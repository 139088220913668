<template>
  <div class="vacation-templates">
    <template
      v-for="n in [
        '0030',
        '0025',
        '0020',
        '0012',
        '0045',
        '0016',
        '0019',
        '0046',
      ]"
    >
      <section :key="`section-${n}`">
        <header>БЛАНКА {{ n }}</header>
        <main>
          <div class="row">
            <Input
              v-model="template[`typeName${n}`]"
              :placeholder="`Име на тип ${n} (плочка за избор)`"
            />
          </div>
          <div class="row">
            <Input
              class="input-wrapper--title"
              v-model="template[`titleOne${n}`]"
              placeholder="Заглавие (ред 1)"
            />
            <Input
              class="input-wrapper--title"
              v-model="template[`titleTwo${n}`]"
              placeholder="Заглавие (ред 2)"
            />
          </div>
          <div class="row">
            <Input
              v-model="template[`userName${n}`]"
              placeholder="Име на служител"
            />
            <Input
              v-model="template[`userNamePlaceholder${n}`]"
              placeholder="Име на служител (упътващ текст)"
            />
          </div>
          <div class="row" v-if="n === '0025' || n === '0012'">
            <Input
              v-model="template[`reasonForVacation${n}`]"
              placeholder="За отсъствие, налагащо се по следната причина"
            />
            <Input
              v-model="template[`reasonForVacationPlaceholder${n}`]"
              placeholder="Въведете причина за отсъствието до 165 знака ... (задължително)"
            />
          </div>
          <div class="row">
            <Input
              v-model="template[`userNumber${n}`]"
              placeholder="Служебен номер"
            />
            <Input
              v-model="template[`userNumberPlaceholder${n}`]"
              placeholder="Служебен номер (упътващ текст)"
            />
          </div>
          <div class="row">
            <Input
              v-model="template[`userPosition${n}`]"
              placeholder="Длъжност"
            />
            <Input
              v-model="template[`userPositionPlaceholder${n}`]"
              placeholder="Длъжност (упътващ текст)"
            />
          </div>
          <!-- 0030 only -->
          <div class="row" v-if="n === '0030'">
            <Input
              v-model="template[`text1Sub1${n}`]"
              placeholder="Доп. текст"
            />
            <Input
              :disabled="true"
              placeholder="&lt;&lt;Име на прекия ръководител&gt;&gt;"
            />
          </div>
          <!-- END 0030 only -->
          <div class="row">
            <Input v-model="template[`text1${n}`]" placeholder="Доп. текст" />
          </div>
          <div class="row">
            <template
              v-if="
                n === '0020' || n === '0019' || n === '0025' || n === '0030'
              "
            >
              <Input v-model="template[`date${n}`]" placeholder="Дата" />
              <Input v-model="template[`hourFrom${n}`]" placeholder="От час" />
              <Input v-model="template[`hourTo${n}`]" placeholder="До час" />
            </template>
            <template v-else>
              <Input v-model="template[`dateFrom${n}`]" placeholder="От дата" />
              <Input v-model="template[`dateTo${n}`]" placeholder="До дата" />
            </template>
            <Input v-model="template[`shift${n}`]" placeholder="График/смяна" />
          </div>
          <div class="row">
            <Input
              v-model="template[`text2part1${n}`]"
              placeholder="Доп. текст"
            />
            <Input
              v-if="n !== '0016'"
              v-model="template[`text2part2${n}`]"
              placeholder="Доп. текст (B)"
              class="input-wrapper--bold"
            />
            <Input
              v-if="n === '0012' || n === '0025'"
              v-model="template[`text2part3${n}`]"
              placeholder="Доп. текст"
            />
          </div>
          <div class="row" v-if="n === '0016'">
            <textarea
              v-model="template[`textarea${n}`]"
              placeholder="Доп. текст"
            />
          </div>
          <div class="row">
            <Input
              v-if="n !== '0012' && n !== '0016'"
              v-model="template[`dateOfSigning${n}`]"
              placeholder="Дата на подписване"
            />
            <Input
              v-else-if="n === '0030'"
              v-model="template[`employee${n}`]"
              placeholder="Служител"
            />
            <Input
              v-model="template[`supervisor${n}`]"
              placeholder="Ръководител"
            />
            <Input
              v-if="n === '0016' || n === '0025' || n === '0012'"
              v-model="template[`employee${n}`]"
              placeholder="Служител"
            />
          </div>
        </main>
      </section>
    </template>
    <div class="vacation-templates__buttons">
      <div>
        <p class="warning">
          <span class="icon">j</span>
          Запазените промени ще се отразят във всички бланки
        </p>
      </div>
      <div class="buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading"
          >Запиши</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import types from '@/data/vacation-types.json';
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  watch: {
    $route: {
      handler: 'getTemplate',
      immediate: true,
    },
  },
  data() {
    return {
      types,
      loading: false,
      success: undefined,
      template: {},
    };
  },
  methods: {
    cancel() {
      this.$router.push('/settings/templates');
    },
    getTemplate() {
      this.$apiService
        .get(`/templates/vacation-hourly`)
        .then((res) => {
          if (res.data) {
            if (res.data._id) {
              this.template = res.data;
            }
          }
        })
        .catch((err) => console.log(err.response.data));
    },
    isValid() {
      for (let key in this.template) {
        if (!(this.template[key] + '').length) {
          this.$snotify.error('Всички полета са задължителни');
          return false;
        }
      }

      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;

        this.$apiService
          .post('/templates', this.template)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            this.success = false;
            console.log(err);
          })
          .then(() => {
            setTimeout(() => {
              if (this.success) {
                this.cancel();
              }

              this.loading = false;
              this.success = undefined;
            }, 1000);
          });
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/scss/base.scss';

.vacation-templates {
  // color: #373737;
  // font-size: $px11;
  margin: 0 auto;
  padding: 40px 0 20px;
  width: 890px;

  section {
    background-color: #f3f3f3;
    border: 1px solid #d0d0d0;
    border-radius: 2px;

    &:not(:first-child) {
      margin-top: 20px;
    }

    header {
      align-items: center;
      background-color: #134685;
      border-radius: 2px;
      color: #fff;
      display: flex;
      font-size: $px11;
      font-weight: 700;
      justify-content: center;
      height: 30px;
    }

    main {
      margin: 0 auto;
      padding: 12.5px 0 18.5px;
      width: 620px;

      .row {
        display: flex;

        textarea {
          border: 1px solid #d3d3d3;
          border-radius: 2px;
          color: #373737;
          font-size: $px12;
          height: 8rem;
          outline: none;
          resize: none;
          width: 100%;
          padding: $px12;
          @include transition($transition);
          &::placeholder {
            color: inherit;
            opacity: 0.35;
            font-style: italic;
          }
          &:focus {
            background-color: #fff;
            box-shadow: 0px 0px 0px 1px #378ef0;
            border: 1px solid #378ef0;
          }
        }

        &:not(:last-child) {
          margin-bottom: 7px;
        }

        .input-wrapper {
          width: 100%;

          &:not(:first-child) {
            margin-left: 21.5px;
          }

          input {
            background-color: #fff;

            &::placeholder {
              color: rgba($color: #000, $alpha: 0.3);
            }
          }

          &.input-wrapper--title {
            input {
              font-weight: 700;
              letter-spacing: 0.369231rem;
            }
          }

          &.input-wrapper--bold {
            input {
              font-weight: 700;
            }
          }

          &.input-wrapper--center {
            input {
              text-align: center;
            }
          }

          &.input-wrapper--right {
            input {
              text-align: right;
            }
          }

          &.input-wrapper--sub {
            input {
              padding-left: 20.5px;
            }
          }
        }
      }
    }
  }

  .vacation-templates__buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;

    .warning {
      align-items: center;
      display: flex;
      color: #df6935;
      font-weight: 500;
      .icon {
        margin-right: 10px;
        font-size: 1.5rem;
      }
    }

    .buttons {
      align-items: center;
      display: flex;
    }

    .button:not(:last-child) {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: $sm) {
  .vacation-templates {
    padding: 10px;
    width: 100%;

    section {
      main {
        width: 100%;
        padding: 10px;
      }
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .vacation-templates {
    section {
      main {
        .row {
          flex-direction: column;
          .input-wrapper:not(:first-child) {
            margin: 10px 0 0;
          }
        }
      }
    }
    .vacation-templates__buttons {
      flex-direction: column;
      .warning {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
